import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from '../constants/index';

const Profile = ({ handleLogout }) => {
  const [profileData, setProfileData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${API_URL}/user-profile/`, config);
        setProfileData(response.data);
      } catch (error) {
        setProfileData(null);
        console.error('Error fetching profile:', error);
        // If error is 401, remove token from local storage
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      }
    };

    fetchProfile();
  }, [getAccessTokenSilently, handleLogout]);

  // This function is called when the user clicks the "Buy 3 Tokens" button
  const handlePurchase = async () => {
    try {
      // Make a request to the Django backend to create a Stripe Checkout session
      const token = await getAccessTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(`${API_URL}/checkout-session/`, {}, config);
      const sessionUrl = response.data.session_url;
      window.location.href = sessionUrl;
    } catch (error) {
      console.error("Error initiating Stripe checkout:", error);
    }
  };

  if (profileData == null) {
    return (
      <div className="profile-page">
        <h2>Welcome!</h2>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
        {/* Logout button */}
      </div>
    );
  }

  return (
    <div className="profile-page">
      <h2>Welcome, {profileData.user.username}!</h2>
      <p>Credits: {profileData.credits} 💎</p>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <button className="logout-button" onClick={handlePurchase}>Buy credits</button>
      <p className="beta-message">
        Glad to see you here. If something went wrong, please message us at{' '}
        <a href="mailto:screenplayer.help@gmail.com">screenplayer.help@gmail.com</a>
      </p>
    </div>
  );
};

export default Profile;
