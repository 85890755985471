import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constants';
import './RegisterInterest.css';

function RegisterInterest() {
    const [email, setEmail] = useState('');
    const [notif, setNotif] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_URL}/register-interest/`, { email });

            setEmail("");
            setNotif(true);
            console.log('Interest registered successfully.');

        } catch (error) {
            setNotif(false);
            console.error('Error:', error);
        }
    };

    return (
        <div className="register-interest-container">
            <form className="interest-form" onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Your email"
                    onChange={e => setEmail(e.target.value)}
                    required
                    className="email-input"
                />
                <button type="submit" className="submit-button">Let me know when I can try it!</button>
                {notif && <div className="success-message">Thank you for being adventurous! Stay tuned!</div>}
            </form>
        </div>
    );
}

export default RegisterInterest;