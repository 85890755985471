import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Login.css';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLoginWithGoogle = () => {
    loginWithRedirect({
      connection: 'google-oauth2',
    });
  };

  return (
    <div className='auth-container'>
      <h2 className='welcome'>Welcome to Screenplayer</h2>
      <button onClick={handleLoginWithGoogle}>Login with Google</button>
    </div>
  );
};

export default Login;
