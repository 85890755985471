import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { API_URL } from '../constants/index';

const Sidebar = ({ items, openProfilePage, onItemClick, fetchDocuments }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [uploadError, setUploadError] = useState(null); // Add uploadError state
  const [isUploading, setIsUploading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const scrollContainerRef = React.useRef(null);

  const handleFileUpload = async (event) => {
    setIsUploading(true);
    setUploadError(null);
    const file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);

    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(`${API_URL}/upload/`, data, config);
      console.log(response.data);
      fetchDocuments();
      setSelectedIndex(0);
      scrollContainerRef.current.scrollTop = 0;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setUploadError(JSON.stringify(error.response.data)); // Set the specific error message
      }
    } finally {
      setIsUploading(false);
    }
  };

  const profileClick = () => {
    setSelectedIndex(''); // Set selectedIndex to empty value
    setUploadError(null);
    openProfilePage(); // Call the openProfilePage function
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="file-upload">
          <h2 style={{ fontSize: '24px', color: '#c62027' }}>New Screenplay</h2>
          <div className="custom-file-input">
            <label htmlFor="file-upload" className="file-label">
              {isUploading ? 'Uploading...' : 'Upload'}
            </label>
            <input type="file" id="file-upload" onChange={handleFileUpload} disabled={isUploading} />
          </div>
          {uploadError && <p className="error-message">{uploadError}</p>} {/* Display the error message */}
        </div>

        <h2>Screenplays</h2>
        <div className="list-section" ref={scrollContainerRef}>
          {items.length === 0 ? (
            <p>Waiting for you to upload your first screenplay! 🦊</p>
          ) : (
            <ul>
              {items.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    onItemClick(index);
                    setSelectedIndex(index);
                  }}
                  className={selectedIndex === index ? 'selected' : ''}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="profile-icon" onClick={profileClick}>
          <span className="profile-icon">
            <img
              src="/profile2.png" // Assuming 'profile.png' is in the public folder
              alt="Profile Icon"
              className="profile-image" // Add a CSS class for styling
            />
            <span className="profile-text">Profile Page</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
