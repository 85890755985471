import React from 'react';
import './Header.css';
import TypingHeader from '../TypingHeader/TypingHeader';
import { FRONT_URL, APP_URL } from '../../../constants';

const Header = () => {
  return (
    <div className="header">
      <div className="header-left">
        <header>
          <a href={FRONT_URL}>
            <TypingHeader text="Screenplayer" />
          </a>
        </header>
      </div>
      <div className="header-right">
        <a href={APP_URL} className="app-link">
          Log in
        </a>
      </div>
    </div>
  );
};

export default Header;
