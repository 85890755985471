import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css';
import AuthApp from './AuthApp';
import Landing from './components/Landing/Landing/Landing';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route exact path="/" element={<Landing/>}/>
          <Route exact path="/app" element={<AuthApp/>}/>
      </Routes>
    </Router>
  </React.StrictMode>,
);
