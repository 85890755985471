import React, { useEffect, useRef } from 'react';
import './ToolDescription.css';
import RegisterInterest from '../RegisterInterest/RegisterInterest';

const ToolDescription = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
          entry.target.classList.remove('fade-out');
        } else {
          entry.target.classList.add('fade-out');
          entry.target.classList.remove('fade-in');
        }
      });
    }, options);

    sectionsRef.current.forEach(section => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach(section => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className='tool-description'>
      <header>
        <h3>From script to screen. Automated budget estimation</h3>
      </header>
    <div className='about-sections'>
      {[
        {
          title: "About Screenplayer",
          content: `New tool designed to simplify and accelerate your initial screenplay analysis and breakdown. Helps producers and screenwriters quickly envision the key elements needed for production and estimate costs with ease. 
Get a first-look analysis to identify what elements are essential for your film before diving deeper.`
},
        {
          title: "How It Works",
          content: `Upload your screenplay, and watch Screenplayer dissect every element of your script, presenting it in neat tables: scene-by-scene breakdowns, character details, locations, costumes, makeup, budget estimation, and useful production insights. Fast, simple, and efficient.`},
        {
          title: "Empowering, Not Replacing",
          content: `There is a fear that AI may eventually render human jobs obsolete. But at Screenplayer, our philosophy is different. We’re here to help and facilitate, not replace. Screenplayer is your co-pilot producer, dealing with the initial mundane and tedious task, so you can analyse your screenplay like a seasoned producer in seconds.`},
        {
          title: "For Filmmakers, By Filmmakers",
          content:`Screenplayer is your go-to assistant for indie film projects, born out of a passion for short films and created by a fellow indie film producer. We designed it to support the indie filmmaking community, where filmmakers often work alone. Think of it as your personal production assistant - here to save you time and money.`
        },
        {
          title: "Affordable & Accessible",
          content: 'Screenplayer is priced for filmmakers at any level. We believe that outstanding tools should be accessible to all, regardless of budgets. That is why we have priced Screenplayer to be affordable, putting quality insights and planning tools well within reach of everyone.'
        },
        {
          title: "Data Privacy: Our Commitment",
          content: `We take data privacy seriously. Once your screenplay is processed, we delete it—no storing, no sharing.Your trust is paramount to us, and that’s why we have a stringent data privacy policy in place: once your screenplay is processed, we delete it. We do not use your screenplay for any AI learning, training or scraping.`},
        {
          title: "Exclusive Access, Future Ready",
          content: "Currently, Screenplayer is accessible by invitation only. However, we're excited about opening up to a wider audience soon. If you're keen on getting notified once we're accessible to everyone, submit your email to register your interest."
        }
      ].map((section, index) => (
        <section
          key={index}
          className="fade-section"
          ref={el => sectionsRef.current[index] = el}
        >
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </section>
      ))}
    </div>
      <RegisterInterest />
    </div>
  );
};

export default ToolDescription;
