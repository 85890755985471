import React from 'react';
import Header from '../Header/Header';
import ToolDescription from '../ToolDescription/ToolDescription';
import './Landing.css';

const Landing = () => {
    return (
        <div className="landing-container">
                <Header />
                <ToolDescription />
        </div>
    );
};

export default Landing;
