import React from 'react';
import './TypingHeader.css';

const TypingHeader = ({ text }) => {
  return (
    <div className="typewriter">
      <h1>{text}</h1>
    </div>
  );
};

export default TypingHeader;
